import React, { useState, useEffect } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiClient } from "../../../config/axios";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoginSchema } from "../../../validation/loginValidation";
import Loader from "../../Loader";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useUser } from "../../DataContext";
import PlainHeader from "../../../common/PlainHeader";

const Login = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { login } = useUser();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(LoginSchema),
  });

  useEffect(() => {
    const savedCredentials = JSON.parse(localStorage.getItem("credentials"));
    if (savedCredentials) {
      setValue("email", savedCredentials.email);
      setValue("password", savedCredentials.password);
      setRememberMe(true);
    }
  }, [setValue]);

  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const onLoginSubmit = async (loginData) => {
    try {
      setLoading(true);
      const res = await apiClient.post(`/api/auth/login`, loginData);
      const data = res.data;
      const accessToken = data.access_token;
      const user = data.user;
      login(user);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", accessToken);
      setLoading(false);
      data?.user?.role === "admin"
        ? navigate("/admin")
        : data?.user?.firstLogin === true
        ? navigate("/account-setup")
        : navigate("/dashboard");
      toast.success("Login Successful");
      if (rememberMe) {
        localStorage.setItem("credentials", JSON.stringify(loginData));
      } else {
        localStorage.removeItem("credentials");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <>
      <PlainHeader />
      <div className="login-container">
        <div className="login-wrapper">
          <form className="login-form" onSubmit={handleSubmit(onLoginSubmit)}>
            <div style={{ marginTop: "30px" }}>
              <label className="mb-[5px]">Email</label>
              <input
                name="email"
                {...register("email")}
                placeholder="Enter Email"
                className="login-input"
              />
              {errors?.email?.message && (
                <p style={{ color: "#CC2828", marginTop: "5px" }}>
                  {errors?.email?.message}
                </p>
              )}
            </div>

            <div style={{ marginTop: "20px" }}>
              <label className="mb-[5px]">Password</label>
              <div className="icon-wrapper">
                <input
                  name="password"
                  {...register("password")}
                  className="login-input"
                  type={passwordType}
                  placeholder="Enter Password"
                />
                <span className="eye-icon" onClick={togglePassword}>
                  {passwordType === "password" ? <FiEyeOff /> : <FiEye />}
                </span>
                {errors?.password?.message && (
                  <p style={{ color: "#CC2828", marginTop: "5px" }}>
                    {errors?.password?.message}
                  </p>
                )}
              </div>
            </div>
            <div
              style={{ marginTop: "20px", marginLeft: "5px" }}
              className="flex gap-2"
            >
              <div className="icon-wrapper">
                <input
                  name="rememberMe"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              </div>
              <label className="mb-[5px] signup-para ">Remember me?</label>
            </div>
            <div
              style={{ marginTop: 10 }}
              className="flex flex-col items-center"
            >
              <div className="relative w-full flex items-center justify-center pt-3">
                {loading && (
                  <div className="absolute inset-0 flex items-center justify-center right-3 top-[25px]">
                    <Loader />
                  </div>
                )}
                <button
                  type="submit"
                  disabled={loading}
                  className={`login-btn flex justify-center items-center bg-primaryColor hover:bg-hoverPrimary ${
                    loading ? "cursor-not-allowed opacity-50" : ""
                  }`}
                >
                  Login
                </button>
              </div>
              <p className="signup-para mt-[29px]">
                If you don’t have an account you can
              </p>
              <Link to={"/signup"} className="no-underline ">
                <p className="text-[#1e1f1e] font-bold text-[14px] mt-[15px] hover:underline">
                  Register here!
                </p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
