import "react-toastify/dist/ReactToastify.css";
import "./AddProfile.css";
import OnBoardingLayout from "../../../layouts/OnBoardingLayout";
import { useUser } from "../../DataContext";
import SocialMediaConnection from "../../../common/SocialMediaConnection";

const AddProfile = () => {
  const { user } = useUser();

  return (
    <OnBoardingLayout selected={1}>
      <div className="bg-white w-full px-3 pt-3 h-screen">
        <div className="flex flex-col items-center mt-24 md:w-8/12 w-full mx-auto ">
          <h1 className="text-2xl text-grey text-center font-semibold">
            Welcome {user?.firstName} !
          </h1>
          <p className="text-lg text-grey -mt-1 text-center">
            Add social media profiles to your Recant.ai account.
          </p>
        </div>
        <SocialMediaConnection />
      </div>
    </OnBoardingLayout>
  );
};

export default AddProfile;
